import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import create_file_from_image_src from "../../Admin/methods/create_file_from_image_src"
import countries from "../../../data/countries"
import { default_variant_title } from "../../../data/other_constants"
import { date_time_format, default_image_src, default_language, orders_API_date_format, payment_methods } from "../constants/other"
import paid_cz from "../../Admin/constants/language_specific_images/paid_cz"
import paid_en from "../../Admin/constants/language_specific_images/paid_en"

const paid_images = {
  cz: paid_cz,
  en: paid_en
}

export default async ({
  order_number, line_items, created_at, customer_locale, shipping_address, subtotal_price, total_tax,
  total_price, total_line_items_price, shipping_lines, currency, total_discounts, billing_address,
  paid_at, financial_status
}, translate, moment, shipping_options, rest_config) => {
  const correct_logo = rest_config.icon || `${window.location.origin}${default_image_src}`
  const logo_file = await create_file_from_image_src(correct_logo)
  const reader = new FileReader()
  const customer_billing_address = {
    ...shipping_address,
    ...billing_address
  }

  reader.addEventListener("load", async () => {
    const data = {
      content: [
        {
          alignment: "justify",
          columns: [
            {
              alignment: "left",
              image: reader.result,
              fit: [100, 50],
            },
            {
              alignment: "right",
              width: 250,
              text: [
                { text: `${await translate("orders.order_print.invoice_title")}\n`, fontSize: 20 },
                { text: `${await translate("orders.order_print.number", { order_number })}\n`, fontSize: 15 },
                {
                  text: await translate("orders.order_print.date_of_creation") + `: ${moment(created_at, orders_API_date_format).format(date_time_format)}\n`,
                  fontSize: 12
                },
                {
                  text: (financial_status === payment_methods.paypal || paid_at) ?
                    `${await translate("orders.order_print.date_of_paymnet")}: ${moment(paid_at || created_at, orders_API_date_format).format(date_time_format)}` :
                    "",
                  fontSize: 12
                },
              ]
            }
          ],
        },
        "\n\n\n\n",
        {
          alignment: "justify",
          columnGap: 20,
          columns: [
            {
              alignment: "right",
              text: [
                { text: `${await translate("orders.order_print.supplier")}:\n`, fontSize: 15, bold: true },
                { text: `${shipping_options.project_address.company}\n`, fontSize: 12 },
                { text: `${shipping_options.project_address.street1}${shipping_options.project_address.street2 ? `, ${shipping_options.project_address.street2}` : ""}\n`, fontSize: 12 },
                { text: `${shipping_options.project_address.zip}\n`, fontSize: 12 },
                { text: `${shipping_options.project_address.city}\n`, fontSize: 12 },
                { text: `${shipping_options.project_address.country ? countries.find(({ code }) => code === shipping_options.project_address.country).name : ""}\n`, fontSize: 12 },
                ...(
                  shipping_options.project_address.businessID ?
                    [{ text: `${await translate("orders.order_print.businessID")}: ${shipping_options.project_address.businessID}\n`, fontSize: 12 }] :
                    []
                ),
                ...(
                  shipping_options.project_address.vatID ?
                    [{ text: `${await translate("orders.order_print.vatID")}: ${shipping_options.project_address.vatID}\n`, fontSize: 12 }] : []
                ),
              ],
            },
            {
              alignment: "right",
              text: [
                { text: `${await translate("orders.order_print.customer")}:\n`, fontSize: 15, bold: true },
                ...(customer_billing_address.company ? [{ text: `${customer_billing_address.company}\n`, fontSize: 12 }] : []),
                { text: `${customer_billing_address.first_name} ${customer_billing_address.last_name}\n`, fontSize: 12 },
                { text: `${customer_billing_address.address1}${customer_billing_address.address2 ? `, ${customer_billing_address.address2}` : ""}\n`, fontSize: 12 },
                { text: `${customer_billing_address.zip}\n`, fontSize: 12 },
                { text: `${customer_billing_address.city}\n`, fontSize: 12 },
                { text: `${customer_billing_address.country}\n`, fontSize: 12 },
                ...(customer_billing_address.businessID ? [{ text: `${await translate("orders.order_print.businessID")}: ${customer_billing_address.businessID}\n`, fontSize: 12 }] : []),
                ...(customer_billing_address.vatID ? [{ text: `${await translate("orders.order_print.vatID")}: ${customer_billing_address.vatID}\n`, fontSize: 12 }] : []),
              ],
            },
            {
              alignment: "right",
              text: [
                { text: `${await translate("orders.order_print.shipping_address")}:\n`, fontSize: 15, bold: true },
                ...(shipping_address.company ? [{ text: `${shipping_address.company}\n`, fontSize: 12 }] : []),
                { text: `${shipping_address.first_name} ${shipping_address.last_name}\n`, fontSize: 12 },
                { text: `${shipping_address.address1}${shipping_address.address2 ? `, ${shipping_address.address2}` : ""}\n`, fontSize: 12 },
                { text: `${shipping_address.zip}\n`, fontSize: 12 },
                { text: `${shipping_address.city}\n`, fontSize: 12 },
                { text: `${shipping_address.country}\n`, fontSize: 12 },
              ],
            },
          ]
        },
        "\n\n",
        {
          table: {
            widths: ["*", 100, 50, 50, 100],
            body: [
              [
                { text: await translate("orders.order_print.description"), bold: true }, { text: await translate("orders.order_print.price"), bold: true, alignment: "right" },
                { text: await translate("orders.order_print.quantity"), bold: true, alignment: "right" }, { text: await translate("orders.order_print.vat"), bold: true, alignment: "right" },
                { text: await translate("orders.order_print.product_total"), bold: true, alignment: "right" }
              ],
              ...line_items.map(({ title, variant_options,  quantity, price, prices }) => [
                `${title}\n${variant_options[0].value === default_variant_title ? "" : variant_options.map(({ name, value }) => `${name}: ${value}`).join(", ")}`,
                { text: `${prices && prices[customer_locale] ? prices[customer_locale].price : price} ${currency}`, alignment: "right" },
                { text: quantity, alignment: "right" },
                { text: String(total_tax > 0 ? (total_tax / (total_line_items_price / 100)).toFixed(1) : 0) + "%", alignment: "right" },
                { text: `${Number(prices && prices[customer_locale] ? prices[customer_locale].price : price) * quantity} ${currency}`, alignment: "right" }
              ])
            ]
          }
        },
        "\n",
        {
          alignment: "justify",
          columns: [
            { 
              ...(
                (financial_status === payment_methods.paypal || paid_at) ?
                { image: paid_images[customer_locale] || paid_images[default_language] } :
                { text: "" }
              ),
              fit: ["*", 140],
              width: 287, 
              alignment: "right",
              margin: [0,0,30,0]
            },
            {
              alignment: "right",
              table: {
                widths: [110, 100],
                body: [
                  [{ text: await translate("orders.order_print.VAT_total"), alignment: "right" }, { text: `${total_tax} ${currency}`, alignment: "right" }],
                  [{ text: await translate("orders.order_print.subtotal"), alignment: "right" }, { text: `${subtotal_price} ${currency}`, alignment: "right" }],
                  [{ text: await translate("orders.order_print.shipping_total"), alignment: "right" }, { text: `${shipping_lines.reduce((tot, { price }) => tot + Number(price), 0)} ${currency}`, alignment: "right" }],
                  [{ text: await translate("orders.order_print.discount"), alignment: "right" }, { text: `${total_discounts > 0 ? `-${total_discounts}` : 0} ${currency}`, alignment: "right" }],
                  [{ text: await translate("orders.order_print.invoice_total"), bold: true, alignment: "right" }, { text: `${total_price} ${currency}`, bold: true, alignment: "right" }],
                ]
              }
            }
          ]
        },
      ]
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(data).download(order_number);
  }, false)

  reader.readAsDataURL(logo_file)
}
